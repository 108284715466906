<template>
  <div class="recorderlist">
    <div class="primary-folder-header">
      <div class="primary-folder-header-tit">
        <span class="pos"></span>
        <p style="white-space: pre-wrap">{{ book.bookName }}</p>
      </div>
      <div class="primary-folder-header-info">
        <span class="pos"></span>
        <div class="info">
          <span class="publisher" v-html="book.publisher"></span>
          <div class="small-box">
            <span class="count">{{ book.studyCount }}人学习</span>
            <router-link
              :to="{
                name: 'propose',
                query: { bid: bid, fid: fid, source: 2 },
              }"
              class="opinion"
            >
              <span class="icon"
                ><img src="@/assets/images/op.png" alt=""
              /></span>
              <em>意见反馈</em>
            </router-link>
          </div>
        </div>
      </div>
      <div class="primary-folder-header-img">
        <img :src="book.coverImg == '' ? cover : book.coverImg" alt="" />
      </div>
    </div>

    <div class="recorderlist-list">
      <div class="recorderlist-list-title">
        <span>{{ rname }}</span>
      </div>

      <router-link
        :to="{
          name: 'resource',
          query: {
            bid: bid,
            fid: fid,
            rid: rid,
            read: read,
          },
        }"
        class="recorderlist-list-item"
      >
        <div class="left">
          <img src="https://res.cdn.quyixian.com/front/play-b.png" alt="" />
          <p>文章朗读</p>
        </div>
        <img
          class="right"
          src="https://res.cdn.quyixian.com/front/right.png"
          alt=""
        />
      </router-link>
      <router-link
        :to="{
          name: 'recorder',
          query: {
            bid: bid,
            rid: rid,
            rname: rname,
            bname: bname,
          },
        }"
        class="recorderlist-list-item"
        v-if="read == 1"
      >
        <div class="left">
          <img src="https://res.cdn.quyixian.com/front/btn-r.png" alt="" />
          <p>跟读练习</p>
        </div>
        <img
          class="right"
          src="https://res.cdn.quyixian.com/front/right.png"
          alt=""
        />
      </router-link>
      <router-link
        :to="{
          name: 'remember',
          query: {
            bid: bid,
            rid: rid,
            rname: rname,
            bname: bname,
          },
        }"
        class="recorderlist-list-item"
        v-if="memory == 1"
      >
        <div class="left">
          <img src="https://res.cdn.quyixian.com/front/btn-abc.png" alt="" />
          <p>词汇速记</p>
        </div>
        <img
          class="right"
          src="https://res.cdn.quyixian.com/front/right.png"
          alt=""
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import qs from "qs";
export default {
  name: "recorderlist",
  data() {
    return {
      bid: this.$route.query.bid == undefined ? "" : this.$route.query.bid,
      rid: this.$route.query.rid == undefined ? "" : this.$route.query.rid,
      fid: this.$route.query.fid == undefined ? "" : this.$route.query.fid,
      rname:
        this.$route.query.rname == undefined ? "" : this.$route.query.rname,
      bname:
        this.$route.query.bname == undefined ? "" : this.$route.query.bname,
      read: this.$route.query.read == undefined ? "" : this.$route.query.read, //是否有跟读
      memory:
        this.$route.query.memory == undefined ? "" : this.$route.query.memory, //是否有速记
      book: "",
      cover: this.$store.state.iCdn + "2021XXNewBook/images/nobook.jpg", //this.book.coverImg
    };
  },

  mounted() {
    this.book = qs.parse(sessionStorage.getItem("bookinfo"));
    if (this.book.bookName == undefined) {
      //访问接口获取
      this.getBook();
    }
    console.log(this.book.bookName);
  },
  methods: {
    //获取图书
    getBook() {
      let params = {
        bid: this.bid,
      };
      this.$api.mary.GetBookInfo(params).then((res) => {
        let { tag, message, data } = res;
        if (tag == 1) {
          if (data.publisher != "" || data.publisher.indexOf("/") > 0) {
            data.publisher = data.publisher.replace(
              new RegExp("\\/", "g"),
              "</br>"
            );
          }
          this.book = data;
          sessionStorage.clear("bookinfo");
          sessionStorage.setItem("bookinfo", qs.stringify(data));
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.recorderlist {
  &-book {
  }
  &-list {
    font-size: 0.4rem;
    font-weight: bold;
    &-title {
      margin: 0.2rem 0.3rem 0.5rem 0.3rem;
      span {
        position: relative;
        z-index: 1;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 0.15rem;
          background: #caf1ff;
          z-index: -999;
        }
      }
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.09);
      margin: 0.5rem 0.3rem;
      padding: 0.3rem 0.5rem;
      border-radius: 1rem;
      color: #333;
      .left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img {
          width: 0.64rem;
          height: 0.64rem;
          margin-right: 0.3rem;
        }
      }
      .right {
        width: 0.4rem;
        height: 0.4rem;
      }
    }
  }
}
</style>
