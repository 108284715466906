var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"recorderlist"},[_c('div',{staticClass:"primary-folder-header"},[_c('div',{staticClass:"primary-folder-header-tit"},[_c('span',{staticClass:"pos"}),_c('p',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(_vm.book.bookName))])]),_c('div',{staticClass:"primary-folder-header-info"},[_c('span',{staticClass:"pos"}),_c('div',{staticClass:"info"},[_c('span',{staticClass:"publisher",domProps:{"innerHTML":_vm._s(_vm.book.publisher)}}),_c('div',{staticClass:"small-box"},[_c('span',{staticClass:"count"},[_vm._v(_vm._s(_vm.book.studyCount)+"人学习")]),_c('router-link',{staticClass:"opinion",attrs:{"to":{
              name: 'propose',
              query: { bid: _vm.bid, fid: _vm.fid, source: 2 },
            }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/op.png"),"alt":""}})]),_c('em',[_vm._v("意见反馈")])])],1)])]),_c('div',{staticClass:"primary-folder-header-img"},[_c('img',{attrs:{"src":_vm.book.coverImg == '' ? _vm.cover : _vm.book.coverImg,"alt":""}})])]),_c('div',{staticClass:"recorderlist-list"},[_c('div',{staticClass:"recorderlist-list-title"},[_c('span',[_vm._v(_vm._s(_vm.rname))])]),_c('router-link',{staticClass:"recorderlist-list-item",attrs:{"to":{
        name: 'resource',
        query: {
          bid: _vm.bid,
          fid: _vm.fid,
          rid: _vm.rid,
          read: _vm.read,
        },
      }}},[_c('div',{staticClass:"left"},[_c('img',{attrs:{"src":"https://res.cdn.quyixian.com/front/play-b.png","alt":""}}),_c('p',[_vm._v("文章朗读")])]),_c('img',{staticClass:"right",attrs:{"src":"https://res.cdn.quyixian.com/front/right.png","alt":""}})]),(_vm.read == 1)?_c('router-link',{staticClass:"recorderlist-list-item",attrs:{"to":{
        name: 'recorder',
        query: {
          bid: _vm.bid,
          rid: _vm.rid,
          rname: _vm.rname,
          bname: _vm.bname,
        },
      }}},[_c('div',{staticClass:"left"},[_c('img',{attrs:{"src":"https://res.cdn.quyixian.com/front/btn-r.png","alt":""}}),_c('p',[_vm._v("跟读练习")])]),_c('img',{staticClass:"right",attrs:{"src":"https://res.cdn.quyixian.com/front/right.png","alt":""}})]):_vm._e(),(_vm.memory == 1)?_c('router-link',{staticClass:"recorderlist-list-item",attrs:{"to":{
        name: 'remember',
        query: {
          bid: _vm.bid,
          rid: _vm.rid,
          rname: _vm.rname,
          bname: _vm.bname,
        },
      }}},[_c('div',{staticClass:"left"},[_c('img',{attrs:{"src":"https://res.cdn.quyixian.com/front/btn-abc.png","alt":""}}),_c('p',[_vm._v("词汇速记")])]),_c('img',{staticClass:"right",attrs:{"src":"https://res.cdn.quyixian.com/front/right.png","alt":""}})]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }